import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const selectUser = (state: RootState) => state.user;

const selectEmail = createSelector(selectUser, (user) => user.email);
const selectUserId = createSelector(selectUser, (user) => user.user_id);
const selectUserName = createSelector(selectUser, (user) => user.userName);
const selectEmailStatusMessage = createSelector(selectUser, (user) => user.emailStatus.message);
const selectEmailStatusIsValid = createSelector(selectUser, (user) => user.emailStatus.isValid);
const selectIsLoading = createSelector(selectUser, (user) => user.isLoading);
const selectToken = createSelector(selectUser, (user) => user.token);
const selectPaymentType = createSelector(selectUser, (user) => user.payment_type);
const selectUpsaleId = createSelector(selectUser, (user) => user.upsaleId);
const selectCountry = createSelector(selectUser, (user) => user.country);
const selectLastUpdateAt = createSelector(selectUser, (user) => user.lastUpdateAt);
const selectIsPaid = createSelector(selectUser, (user) => user.isPaid);
const selectIsInitTestania = createSelector(selectUser, (user) => user.isInitTestania);
const selectShouldUserMoveToPage = createSelector(selectUser, (user) => user.shouldUserMoveToPage);
const selectDefaultAmazonAnalyticData = createSelector(selectUser, (user) => user.defaultAmazonAnalyticData);
const selectPlatform = createSelector(selectUser, (user) => user.platform);
const selectSignUpErrorData = createSelector(selectUser, (user) => user.signUpErrorData);
const selectIsUpdateInfoSuccess = createSelector(selectUser, (user) => user.isUpdateInfoSuccess);

export {
    selectUser,
    selectEmail,
    selectUserId,
    selectUserName,
    selectEmailStatusMessage,
    selectEmailStatusIsValid,
    selectIsLoading,
    selectToken,
    selectPaymentType,
    selectUpsaleId,
    selectCountry,
    selectLastUpdateAt,
    selectIsPaid,
    selectIsInitTestania,
    selectShouldUserMoveToPage,
    selectDefaultAmazonAnalyticData,
    selectPlatform,
    selectSignUpErrorData,
    selectIsUpdateInfoSuccess,
};
