export const OtherPages = [
    // General Pages
    {
        path: 'GeneralPages/PaymentApprovedUpdated',
        route: 'payment-approved',
    },

    // OTHER PAGES
    {
        path: 'OtherPages/LoadBar',
        route: 'load-bar',
    },
    {
        path: 'OtherPages/VipSupport',
        route: 'vip-support',
    },
    {
        path: 'OtherPages/UpgradePlan',
        route: 'upgrade-plan',
    },

    // CABINET
    {
        path: 'Cabinet/CheckMailbox',
        route: 'check-mailbox',
    },
    {
        path: 'Cabinet/ForgotPassword',
        route: 'forgot-password',
    },
];
